<!-- =========================================================================================
    File Name: NotAuthorized.vue
    Description: Not Authorized Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="h-screen flex w-full bg-img">
    <div
      class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center"
    >
      <img
        src="../../assets/images/pages/not-authorized.png"
        alt="graphic-not-authorized"
        class="mx-auto mb-4"
      />
      <h1 class="sm:mx-0 mx-4 sm:mb-12 mb-8 text-5xl d-theme-heading-color">
        You are not authorized!
      </h1>
      <!-- <p class="sm:mx-0 mx-4 sm:mb-12 mb-8 d-theme-text-inverse">paraphonic unassessable foramination Caulopteris worral Spirophyton encrimson esparcet aggerate chondrule restate whistler shallopy biosystematy area bertram plotting unstarting quarterstaff.</p> -->
      <!-- <vs-button size="large" @click="sendBack">Back to Home</vs-button> -->
      <vs-button size="large" @click="sendBack">Back to Login</vs-button>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { BASEURL } from "@/config/index.js";
import { TokenService } from "@/services/storage.service.js";
export default {
  data() {
    return {};
  },
  mounted() {
    localStorage.clear();
  },
  components: {},
  methods: {
    sendBack: function() {
      let userRole = window.localStorage.getItem("UserRole");
      // if (
      //   userRole == "Admin Manager" ||
      //   userRole == "Moderator" ||
      //   userRole == "Dashboard User"
      // ) {
      //   this.$router.push({
      //     path: "/apps/projects"
      //   });
      // } else {
      //   this.$router.push({
      //     path: "/"
      //   });
      // }
      this.$router.push({
        path: "/"
      });
    }
  }
};
</script>
